import React, { useState, useEffect } from 'react';
import {
    Input, FormGroup, FormFeedback, Button, Row, Col, Spinner
} from "reactstrap"
import '../home.css'
import PropTypes from 'prop-types';
import { deviceType, osName, browserName } from 'react-device-detect';
import isEmail from 'validator/lib/isEmail'

export default function EmailValidation(props) {

    const { countrycode, transactiondate, receiptId, translation, locale } = props
    const MainState = props.mainState;
    const [emailBtnDisabled, setEmailBtnDisabled] = useState(MainState.emailBtnDisabled);
    const [emailId, setEmailId] = useState("");
    const [isComponentLoaded, setIsComponentLoaded] = useState(true);
    const [validate, setValidate] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(true);
    const [showEmailSentMsgObj, setShowEmailSentMsgObj] = useState({
        showEmailSentMsg: false,
        showEmailSentMsgContent: <strong>{translation('You have reached the maximum number of emails you can send(4).Please use the download function instead.')}</strong>
    });
    useEffect(() => {
        if (isComponentLoaded) {
            setIsComponentLoaded(false);
            setValidate(true)
        }
    }, [isComponentLoaded]);
    function validateEmail(e) {
        setIsEmailEmpty(true);
        setEmailId(e.target.value);
        validateEmailText(e.target.value);
    }
    function validateEmailText(emails)
    {
        const gettingTheEmailValidation = isEmail(emails);
        setEmailBtnDisabled(!gettingTheEmailValidation);
        setValidate(gettingTheEmailValidation)
    }
    function SendEmailEvent() {
        if (MainState.isLoading) return;

        if (!isEmail(emailId) || MainState.emailSentCount >= 4) {
            setShowEmailSentMsgObj(prevState => {
                return {
                    showEmailSentMsg: true, showEmailSentMsgContent: <strong>{translation('You have reached the maximum number of emails you can send(4).Please use the download function instead.')}</strong> } });
            return;
        }
        SendEmailReceipt();
    }
    function handleInputBlur(e) {
        
            setIsEmailEmpty(false);
        setEmailBtnDisabled(!isEmail(e.target.value));
        setValidate(isEmail(e.target.value));
    };
    const SendEmailReceipt = async () => {
        if (props.callDateDiff < 0) {
            props.setExpireState();
            setEmailId("");
            setIsEmailEmpty(true);
            return;
        }
        props.callLoading(true);

        const EmailCustomerInfos = {
            BusinessPartnerId: null,
            CountryCode: countrycode,
            FirstName: null,
            LastName: null,
            CorporateBrandId: 0,
            CorporateBrand: "HM",
            Base64PDF: MainState.base64PDF,
            Base64BarCodeImage: MainState.barcode,
            Locale: locale,
            CustomerLoyaltyId: null,
            EmailAddres: emailId,
            TransactionDate: transactiondate
        };
        const NonMemberReceiptBlobsIndexRequest = {
            ReceiptId: receiptId,
            TransactionDate: transactiondate,
            CountryCode: countrycode,
            EmailSentCount: MainState.emailSentCount + 1,
            DeviceType : deviceType,
            OsName : osName,
            BrowserName: browserName
        }
        const emailCountAndCustomerInfo = {
            EmailCustomerInfos,
            NonMemberReceiptBlobsIndexRequest
        }
        const response = await fetch('receiptline/sendemail', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(emailCountAndCustomerInfo)
        });
        const data = await response.json();
        props.callLoading(false);

        if (data?.error != null) {
            if (data.error !== "Success") {
                setShowEmailSentMsgObj(prevState => {
                    return {
                        showEmailSentMsg: true, showEmailSentMsgContent: <strong>{translation('Unable to send receipt. Please try again or download the receipt.')}</strong>
                    } });
                return;
            }

            setShowEmailSentMsgObj(prevState => { return { showEmailSentMsg: false } });
            props.updateInput(emailId, true);
            props.updateEmailSenCount();
            props.callLoading(false);
        }
    }
    const GetClassName = (validate ? " drs_sendreceipt_textbox_validImg" : " drs_sendreceipt_textbox_invalidImg");
    return (
        <div>
            <Row>
                <Col xs="12" sm="12">
                    <FormGroup>
                        <h6 className="drs_Email_Txt" >{translation('Email')}</h6>
                        <Input
                            className={`drs_sendrecipt_textbox ${isEmailEmpty ? "" : GetClassName}`}
                            type="email"
                            disabled={MainState.emailInputDisable}
                            id="customer_email"
                            name="email"
                            autocomplete="off"
                            onBlur={(e) => { handleInputBlur(e) }}
                            onChange={(e) => {
                                validateEmail(e);
                            }}
                            onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    SendEmailEvent()
                                }
                            }}
                            value={emailId}
                            invalid={!validate && isEmailEmpty}
                        />
                        <FormFeedback invalid className="drs_email_error_message">
                            <strong>{translation('Please enter a valid email')}</strong>
                        </FormFeedback>

                    </FormGroup>
                </Col>
            </Row>
            <Row style={{
            }}>
                <Col xs="12" sm="12">
                    <Button className="drs_sendrecipt_btn" style={{
                        backgroundColor: emailBtnDisabled ? "#E4E4E4" : "#222222",
                        borderColor: emailBtnDisabled ? "#E4E4E4" : "#222222",
                        color: emailBtnDisabled ? "#707070" : "#FFFFFF",
                        borderRadius: "0px"
                    }} id="send_button" onClick={SendEmailEvent.bind(this)}
                        name="send"
                        disabled={(emailBtnDisabled) ? "disabled" : ""}
                    > {MainState.isLoading && <Spinner size="sm"> Loading...</Spinner>}
                        <span>{' '}{MainState.isLoading ? <strong>{translation('Sending Email')}</strong> : <strong>{translation('Send receipt to email')}</strong>}</span>
                    </Button>
                    {showEmailSentMsgObj.showEmailSentMsg &&
                        <span className="drs_email_error_message">
                            {showEmailSentMsgObj.showEmailSentMsgContent}
                        </span>
                    }
                </Col>
            </Row>
        </div>
    );
}
EmailValidation.propTypes = {
    transactiondate: PropTypes.string,
    translation: PropTypes.func,
    countrycode: PropTypes.string,
    receiptId: PropTypes.string,
    locale: PropTypes.string,
    callDateDiff: PropTypes.number,
    setExpireState: PropTypes.func,
    mainState: PropTypes.object,
    callLoading: PropTypes.func,
    updateInput: PropTypes.func,
    updateEmailSenCount: PropTypes.func
};