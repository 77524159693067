/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import Logo from '../components/BrandLogo'
import EmailValidation from '../components/EmailValidation'
import ErrorComponent from '../components/Error'
import '../home.css'
import Confirmation from '../components/Confirmation'
import { Container, Row, Col, Spinner } from "reactstrap"
import DownloadReceipt from '../components/DownloadReceipt';
import { useTranslation } from "react-i18next";
import { deviceType, osName, browserName } from 'react-device-detect';
import PropTypes from 'prop-types';
export default function MainPage() {
    const [executing, setExecuting] = useState(true);
    const search = window.location.search;
    const query = new URLSearchParams(search);
    const crypticdata = query.get('cd')
    const { t, i18n } = useTranslation();

    let countrycode = "";
    let transactiondate = "";
    let receiptId = ""
    let brandId = "";

    const [mainState, setMainState] = useState({
        receiptLines: [],
        loading: true,
        showMessage: false,
        counter: 0,
        popup: false,
        emailBtnDisabled: true,
        previousEmail: "",
        isLoading: false,
        emailId: "",
        emailInputDisable: true,
        conditionPopup: false,
        emailSent: false,
        sim: false,
        emailSentCount: 0,
        isReceiptExpired: false,
        countrycode: "",
        transactiondate: "",
        receiptId: "",
        locale: "",
        enableEmailOptionByCountryAndBrandId: false,
    });

    const [showHeader, setShowHeader] = useState({
        showlogo: false,
        brandId: "",
        showMessage: false

    });

    const [showReceiptExpirationMessage, setShowReceiptExpirationMessage] = useState({
        showReceiptExpirationMessageText: true,
        date_diff_indays: 0,
        receiptViewExpiryDate: ""
    });

    useEffect(() => {

        if (!executing) return;

        async function populateReceiptData(counter) {

            let timer = 0;
            let templUrlGetDate = 'receiptline/crypticdata=' + crypticdata + '&deviceType=' + deviceType + '&osName=' + osName + '&browserName=' + browserName;
            const getDataResponse = await fetch(templUrlGetDate);
            const getData = await getDataResponse.json();

            if (!getData?.status) {
                clearTimeout(timer);
                setMainState({ ...mainState, receiptLines: [], loading: false, showMessage: true });
            }
                countrycode = getData.countryCode;
                transactiondate = getData.transactionDate;
                receiptId = getData.receiptId;
                brandId = getData.brandId;
                setShowHeader(prevState => {
                    return {
                        ...prevState,
                        brandId: getData.brandId,
                        showlogo: true,
                        showMessage: true
                    }
                });

                setShowReceiptExpirationMessage(prevState => {
                    return {
                        ...prevState,
                        showReceiptExpirationMessageText: getData.showReceiptExpirationMessage,
                        date_diff_indays: getData.receiptExpiryDays,
                        receiptViewExpiryDate: getData.receiptViewExpiryDate
                    }
                });

            updateChangeLanguage(getData.disableTranslation, countrycode, brandId);

            if (transactiondate != null && getData.receiptExpiryDays < 0) {
                setExpireState();
                return;
            }
            if (countrycode.length != 2 || !/^[A-Za-z]+$/.test(countrycode)) {
                setMainState({ ...mainState, receiptLines: [], loading: false, showMessage: true });
                return;
            }
            getData.DeviceType = deviceType;
            getData.IsGetReceipt = true;
            getData.Crypticdata = crypticdata;
            getData.OSName = osName;
            getData.BrowserName = browserName;
            const response = await fetch('receiptline/postreceipt', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(getData)
            });
            const data = await response.json();

            let isRceiptData = checkTheReceiptData(data, counter, getData);
            if (!isRceiptData) {
                setTimeout(() => {
                    const tempCounter = ++mainState.counter;
                    settingMainstate(tempCounter);
                    populateReceiptData(tempCounter);
                }, 2500);
                return;
            }
            clearTimeout(timer);
        }
        populateReceiptData(mainState.counter);

    }, [executing])
    function settingMainstate(increaseCounter)
    {
        setMainState(prevState => {
            return { ...prevState, counter: increaseCounter }
        });
    }
    function checkTheReceiptData(data, counter, getData) {
        
        if ((data != null && (data.error == "error" || data.error == "Failure")) || counter > 2)
        {
            setMainState({ ...mainState, receiptLines: [], loading: false, showMessage: true });
            return true;
        }
        else if (data != null && data.receiptLines.length > 0) {
            setMainState(prevState => (
                {
                    ...prevState,
                    receiptLines: data.receiptLines,
                    barcode: data.barcodeImage,
                    loading: false,
                    base64PDF: data.base64PDF,
                    emailInputDisable: false,
                    emailSentCount: (data.emailSentCount == "") ? 0 : Number(data.emailSentCount),
                    countrycode: getData.countryCode,
                    receiptId: getData.receiptId,
                    transactiondate: getData.transactionDate,
                    locale: getData.locale,
                    enableEmailOptionByCountryAndBrandId: getData.enableEmailOptionByCountryAndBrandId
                }
            ));

            setExecuting(false);
            return true;
        }
        else {
           return false
        }
    }
    function updateChangeLanguage(disableTranslation, countrycode, brandId) {
        if (!disableTranslation) {
            i18n.changeLanguage(countrycode.toLowerCase() + "_" + brandId);
        }
    }
    function updateEmailInput(emailId, validate) {
        setMainState(prevState => { return { ...prevState, emailId: emailId, emailSent: validate } })
    }
    function callLoading(isLoading) {
        setMainState(prevState => { return { ...prevState, isLoading: isLoading } });
    }
    function updateEmailSenCount() {
        setMainState(prevState => { return { ...prevState, emailSentCount: mainState.emailSentCount + 1 } });
    }
    function setExpireState() {
        setMainState(prevState => {
            return {
                ...prevState, showMessage: true, isReceiptExpired: true, emailInputDisable: true, emailBtnDisabled: true
            }
        });
    }
    return (
        <div>
            <Container>
                { /** Logo Load ***/}
                {showHeader.showlogo &&
                    <div>
                        <Row className="drs_logo_margin">
                            <Col xs="12" sm="12">
                                <div className="drs_reciept_hm_logo_div">
                                    <Logo brandId={showHeader.brandId}></Logo>
                                </div>
                            </Col>
                        </Row>
                    </div>
                }

                { /** Thank You Message Load ***/}
                {showHeader.showMessage &&
                    <div>
                        <Row className="drs_title_margin">
                            <Col xs="12" sm="12"> <h4 className={'drs_receipt_tree_logo_text_' + showHeader.brandId}>{t('Thanks for selecting a digital receipt!')}</h4></Col>
                        </Row>
                        {showHeader.brandId != "0" &&
                            <Row className="drs_body_margin">
                                <Col xs="12" sm="12"> <h6 className={'drs_receipt_tree_body_text_' + showHeader.brandId}>{t('body message')}</h6></Col>
                            </Row>
                        }
                    </div>
                }
                { /** Email text box load ***/}
                {!mainState.emailSent && mainState.enableEmailOptionByCountryAndBrandId &&
                    <div>

                        <Row className="drs_email_container">
                            <Col xs="12" sm="12">
                                <EmailValidation
                                    updateInput={updateEmailInput}
                                    callLoading={callLoading}
                                    updateEmailSenCount={updateEmailSenCount}
                                    mainState={mainState}
                                    receiptId={mainState.receiptId}
                                    transactiondate={mainState.transactiondate}
                                    countrycode={mainState.countrycode}
                                    callDateDiff={showReceiptExpirationMessage.date_diff_indays}
                                    setExpireState={setExpireState}
                                    translation={t}
                                    locale={mainState.locale}
                                />
                            </Col>
                        </Row>
                    </div>
                }
                {mainState.emailSent &&
                    <div>
                        <Confirmation
                            emailId={mainState.emailId}
                            transactiondate={mainState.transactiondate}
                            translation={t}
                            countrycode={mainState.countrycode}
                            receiptId={mainState.receiptId}
                            locale={mainState.locale}
                            brandId={showHeader.brandId}
                            updateInput={updateEmailInput}
                            callDateDiff={showReceiptExpirationMessage.date_diff_indays}
                        />
                    </div>
                }
                {!mainState.loading && !mainState.showMessage &&
                    <div>
                        <DownloadReceipt mainState={mainState} showReceiptExpirationMessage={showReceiptExpirationMessage}
                            receiptId={mainState.receiptId}
                            transactiondate={mainState.transactiondate}
                            countrycode={mainState.countrycode}
                            callDateDiff={showReceiptExpirationMessage.date_diff_indays}
                            setExpireState={setExpireState}
                            brandId={showHeader.brandId}
                            locale={mainState.locale}
                            translation={t}

                        ></DownloadReceipt>
                    </div>}
                {
                   !mainState.showMessage
                        ? <DataDisplay mainState={mainState} showHeader={showHeader}  />
                        : <ErrorComponent
                            receiptId={receiptId}
                            isReceiptExpired={mainState.isReceiptExpired}
                            translation={t} />
                }
            </Container>
        </div >
    )
}
const DataDisplay = ({ mainState, showHeader }) =>
(!mainState.loading ?
    (
        <div className={'drs_pdf_content_div_center_' + showHeader.brandId} style={{ backgroundColor: "white" }}>
            <p className={'drs_pdf_content_paragraph_center_' + showHeader.brandId}>
                {mainState?.receiptLines?.map((user) =>
                (
                    <span key={"_keys_" + user}>
                        <pre>{user}</pre>
                    </span>
                ))}
            </p>
            <img className="barcode_image" alt="" src={`data:image/png;base64,${mainState.barcode}`} ></img>
            <span><pre className={'drs_barcode_text_' + showHeader.brandId}>{mainState.receiptId}</pre></span>
        </div>
    )
    : (<div className="drs_loading_gif">
        <Row className="drs_loading_gif_row_margin"><Col xs="5" sm="5"></Col>
            <Col xs="12" sm="12" style={{ textAlign: "center" }}>
                <Spinner style={{ borderColor: "red", borderRightColor: "transparent" }} variant="danger">Loading...</Spinner>
            </Col>
            <Col xs="12" sm="12" style={{ textAlign: "center" }} className={'drs_receipt_tree_logo_text_' + showHeader.brandId}>Receipt Loading...</Col>
        </Row>
    </div>)
);
DataDisplay.propTypes = {
    mainState: PropTypes.object,
    showHeader:PropTypes.object
};