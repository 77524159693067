import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import  MainPage  from './Pages/MainPage';


import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
      return (
          <Switch>
              <Route path="/:countrycode:transactiondate:receiptId"  >
                  <MainPage />
              </Route>
          </Switch>

    );
  }
}
