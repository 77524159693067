import React from 'react';
import '../home.css';
import hmLogo from '../images/hmicon.svg';
import cos from '../images/cosicon.svg';
import monki from '../images/monkiicon.svg';
import weekday from '../images/weekdayicon.svg';
import aos from '../images/otherstoriesicon.svg';
import ark from '../images/arketicon.svg';
import PropTypes from 'prop-types';
let imgURL = {
    0: hmLogo,
    1: cos,
    2: monki,
    3: weekday,
    //4: cm,
    5: aos,
    6: ark
}
export default function BrandLogo(props) {

    const { brandId } = props;

    return (
        <img src={imgURL[brandId]} alt="hm_logo" className="drs_logo_img"></img>
    );
}
BrandLogo.propTypes = {
    brandId: PropTypes.string
};