import React from 'react';
import { Row, Col } from "reactstrap";
import '../home.css';
import CombinedShape from '../images/CombinedShape.svg'
import PropTypes from 'prop-types';

export default function ErrorComponent(props) {
    const { receiptId, isReceiptExpired, translation } = props;

    return (
        <div className="drs_loading_gif">
            <Row className="drs_loading_gif_row_margin_error">
                <Col xs="12" sm="12" >
                    <img style={{
                        marginLeft: "47%", marginTop: "21%"
                    }} src={CombinedShape} alt="combined_shape" width="20" height="20" />
                </Col>
                <Col xs="12" sm="12" style={{
                    textAlign: "center", marginTop: "5%", fontWeight: "400",
                    fontSize: "13px",
                    fontFamily: 'HM Sans'
                }}><div className="drs_error_h5">
                        {
                            !isReceiptExpired &&
                            <h6 className="drs_error_h6">{translation('Receipt ID')} <b>{receiptId}</b> {translation('has failed to load!')}
                                <br />
                                    {translation('Please contact our customer service.')}</h6>
                        }
                        {
                            isReceiptExpired &&
                            <h6 className="drs_error_h6">
                                    {translation('Your receipt')} <b>{receiptId}</b> {translation('has expired')}
                                <br />
                                    {translation('If you need your receipt, please contact our customer service.')}
                            </h6>
                        }

                    </div></Col>
            </Row>
        </div>

    );
}
ErrorComponent.propTypes = {
    isReceiptExpired: PropTypes.bool,
    receiptId: PropTypes.string,
    translation: PropTypes.func
};