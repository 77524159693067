import React from 'react';
import { Button, Row, Col } from "reactstrap"
import PropTypes from 'prop-types';
import '../home.css'
import { deviceType, osName, browserName } from 'react-device-detect';

export default function Confirmation(props) {
    const { emailId, transactiondate, translation, countrycode, receiptId, locale, brandId } = props;

    const sendPlayStore = async () => {
        const startDateTime = new Date().toISOString();

        if (props.callDateDiff < 0) {
            props.setExpireState();
            props.updateInput(emailId, false);
            return;
        }

        let url = "https://www2.hm.com/" + locale.toLowerCase() + "/member/info.html";
        window.open(url);

        const endDateTime = new Date().toISOString();

        const customDimensions = {
            Countrycode: countrycode,
            ReceiptId: receiptId,
            Transaction: transactiondate,
            DeviceType: deviceType,
            OsName: osName,
            BrowserName: browserName,
            Locale: locale,
            BrandId: brandId
        }
        const CaptureNonmemberLogs = {
            ProcessName: "DrsNonmemberReceiptsUIMemberButtonClick",
            MessageId: "",
            StartTime: startDateTime,
            EndTime: endDateTime,
            Status: "Success",
            ErrorMessage: "",
            CustomDimensions: customDimensions
        }

         await fetch('receiptline/capturelogs', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(CaptureNonmemberLogs)
        });
    }
    function closebtn() {
        props.updateInput(emailId, false);
    }
    return (
        <div>
            <Row className="confirmation_close" onClick={closebtn.bind(this)}>
            </Row>
            <Row className="drs_title_margin">
                <Col xs="12" sm="12" md="12" className="drs_receipt_tree_logo_text_left">
                    <h4 className="drs_receipt_tree_logo_text">{translation('Receipt has been sent!')} </h4>
                    <h6 className="drs_sub_title">{translation('The receipt has been sent to')} {emailId} </h6>
                </Col>
            </Row>
            <div className="drs_confirmation_container">
                <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                    <Col xs="12" sm="12" md="12" style={{ marginTop: "0.7rem" }}>
                        <div className="drs_email_confirmation_h5">
                            <h5 className="drs_confirmation_main_desp">{translation('Collect your receipts in a H&M member account instead!')}</h5>
                        </div>
                        <h6 className="drs_email_confrimation_desp">{translation('Become a H&M member and register your membership at next purchase and collect your digital receipt at your account.')}</h6>
                    </Col>
                    <Col xs="12" sm="12" md="12" style={{ marginTop: "-1%" }}>
                        <Button className="drs_join_btn" style={{
                            backgroundColor: "#222222",
                            borderColor: "#222222",
                            color: "#FFFFFF",
                            borderRadius: "0px"
                        }} id="join_button" onClick={sendPlayStore.bind(this)}
                            name="join"
                        >{translation('Join now')}</Button>
                    </Col>
                    <Col xs="12" sm="12" md="12" style={{ height: "14px" }}>
                    </Col>
                    <Col xs="12" sm="12" md="12" style={{ height: "5px" }}>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
Confirmation.propTypes = {
    emailId: PropTypes.string,
    transactiondate: PropTypes.string,
    translation: PropTypes.func,
    countrycode: PropTypes.string,
    receiptId: PropTypes.string,
    locale: PropTypes.string,
    brandId: PropTypes.string,
    callDateDiff: PropTypes.number,
    setExpireState: PropTypes.func,
    updateInput: PropTypes.func
};