import React, { useState } from 'react';
import { Row } from "reactstrap"
import downloadApp from '../images/DownloadApp.svg'
import '../home.css'
import PropTypes from 'prop-types';
import { isIOS, deviceType, osName, browserName, isChrome } from 'react-device-detect';
import { TouchableOpacity, StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    div: {
        float: 'right'
    }
});
export default function DownloadReceipt(props) {
    const MainState = props.mainState;
    const ShowReceiptExpirationMessage = props.showReceiptExpirationMessage;
    const { countrycode, transactiondate, receiptId, translation, locale, brandId } = props
    const [showDownloadMsgObj, setShowDownloadMsgObj] = useState({ showDownloadMsg: false, showDownloadMsgContent: "" });

    const downloadreceipt = async (pdfData) => {
        if (props.callDateDiff < 0) {
            props.setExpireState();
            return;
        }
        const startDateTime = new Date().toISOString();

        const fileName = "hm_digitalreceipt_" + receiptId + '.pdf';

        try {

            if (pdfData) {
                let iOS = isIOS;
                let iOSVersion = [];
                iOSVersion = CheckiOSVersion(iOS, iOSVersion);
                let attachmentData = pdfData;
                let attachmentName = fileName;
                let contentType = "application/pdf";
                let binary = atob(attachmentData.replace(/\s/g, ""));
                let len = binary.length;
                let buffer = new ArrayBuffer(len);
                let view = new Uint8Array(buffer);
                for (let i = 0; i < len; i++) {
                    view[i] = binary.charCodeAt(i);
                }
                let linkElement = document.createElement("a");
                let hrefUrl = "";
                let getURLParameter = updateURLParameter(iOS, isChrome, iOSVersion, pdfData, contentType, view);
                hrefUrl = getURLParameter.hrefUrl;
                linkElement.setAttribute("href", hrefUrl);
                linkElement.setAttribute("target", "_blank");

                if ((iOS && (iOSVersion[0] > 12 || isChrome)) || !iOS) {
                    linkElement.setAttribute("download", attachmentName);
                }

                let clickEvent = new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false
                });
                linkElement.dispatchEvent(clickEvent);

                DownloadLogs(startDateTime, "Success", "");
            }
            else {
                DownloadLogs(startDateTime, "Error", "Empty pdfData");
            }
        }
        catch {
            setShowDownloadMsgObj(prevState => {
                return {
                    showDownloadMsg: true, showDownloadMsgContent: <strong>{translation('Download has failed. Please try again or send the receipt to your email')}</strong>
                }
            });

            DownloadLogs(startDateTime, "Error", "Download has failed");

            return;
        }
    };
    function updateURLParameter(iOS, isChrome, iOSVersion, pdfData, contentType,view) {
        let blob = "";
        let hrefUrl = "";
        if (iOS && !isChrome && iOSVersion[0] <= 12) {
            blob = "data:application/pdf;base64," + pdfData;
            hrefUrl = blob;
        }
        else {
            if (iOS && !isChrome) {
                contentType = "application/octet-stream";
            }
            blob = new Blob([view], { type: contentType });
            hrefUrl = window.URL.createObjectURL(blob);
        }
        return {hrefUrl, contentType};
    }
    function CheckiOSVersion(iOS, iOSVersion) {
        if (iOS) {
            let userAgent = navigator.userAgent;
            let regex = /OS [\d_]+/i;
            iOSVersion = regex.exec(userAgent)[0]
                .substring(3)
                .split("_")
                .map((n) => parseInt(n));
        }
        return iOSVersion;
    }

    const DownloadLogs = async (startDateTime, status, errorMsg) => {

        const endDateTime = new Date().toISOString();

        const customDimensions = {
            Countrycode: countrycode,
            ReceiptId: receiptId,
            Transaction: transactiondate,
            DeviceType: deviceType,
            OsName: osName,
            BrowserName: browserName,
            Locale: locale,
            BrandId: brandId
        }
        const CaptureNonmemberLogs = {
            ProcessName: "DrsNonmemberReceiptsUIDownload",
            MessageId: "",
            StartTime: startDateTime,
            EndTime: endDateTime,
            Status: status,
            ErrorMessage: errorMsg,
            CustomDimensions: customDimensions
        }

        await fetch('receiptline/capturelogs', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(CaptureNonmemberLogs)
        });
    }

    return (
        <div>
            <Row className="drs_loading_gif_row_margin">
                {ShowReceiptExpirationMessage.showReceiptExpirationMessageText &&
                    <h5 className="drs_receipt_expiryDate" >{translation('Your receipt will expire on')}: <b>{ShowReceiptExpirationMessage.receiptViewExpiryDate}</b></h5>
                }
                <TouchableOpacity style={styles.div} hitSlop={{ top: 48, bottom: 48, left: 48, right: 48 }}>
                    <input type="image" className="drs_receipt_download" src={downloadApp} alt="download_app" onClick={() => downloadreceipt(MainState.base64PDF)}></input>
                    {showDownloadMsgObj.showDownloadMsg &&
                        <span className="drs_download_error_message">
                            {showDownloadMsgObj.showDownloadMsgContent}
                        </span>
                    }
                </TouchableOpacity>
            </Row>
        </div>
    );
}
DownloadReceipt.propTypes = {
    transactiondate: PropTypes.string,
    translation: PropTypes.func,
    countrycode: PropTypes.string,
    receiptId: PropTypes.string,
    locale: PropTypes.string,
    brandId: PropTypes.string,
    callDateDiff: PropTypes.number,
    setExpireState: PropTypes.func,
    mainState: PropTypes.object,
    showReceiptExpirationMessage: PropTypes.object
};