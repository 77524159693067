import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationFR_0 from "./locales/fr/0_translation.json";
import translationGB_0 from "./locales/gb/0_translation.json";
import translationGB_1 from "./locales/gb/1_translation.json";
import translationGB_2 from "./locales/gb/2_translation.json";
import translationGB_3 from "./locales/gb/3_translation.json";
import translationGB_4 from "./locales/gb/4_translation.json";
import translationGB_5 from "./locales/gb/5_translation.json";
import translationGB_6 from "./locales/gb/6_translation.json";

const resources = {
    en: { translation: translationEN, },
    fr: { translation: translationFR, },
    fr_0: { translation: translationFR_0, },
    gb_0: { translation: translationGB_0, },
    gb_1: { translation: translationGB_1, },
    gb_2: { translation: translationGB_2, },
    gb_3: { translation: translationGB_3, },
    gb_4: { translation: translationGB_4, },
    gb_5: { translation: translationGB_5, },
    gb_6: { translation: translationGB_6, },
};

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        resources,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;